import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { PageProps, graphql } from 'gatsby'

import Navigation from 'components/layout/Navigation'
import Footer from 'components/layout/Footer'
import FlatForm from 'components/layout/Forms/FlatForm'
import SimilarFlatCard from 'components/layout/SimilarFlatCard'

import Container from 'components/shared/container'
import Icon from 'components/shared/icon'
import { Heading, Text } from 'components/shared/typography'

import pdfIcon from 'assets/icons/pdf.svg'
import rectangleLight from 'assets/icons/rectangle-light.svg'
import background from 'assets/images/background-diamond-pattern.png'

import useBreakpoint from 'hooks/useBreakpoint'

import { similarFlatsExample } from 'assets/test-data'

import type { FlatCRM } from 'types/flat'

const getFlatStatusLabel = (statusNumber: number): string => {
  switch (statusNumber) {
    case 0:
      return 'Sprzedane'
    case 1:
      return 'Zarezerwowane'
    case 2:
      return 'Dostępne'
    default:
      return ''
  }
}

const footerDescription =
  'Firma Borstar to jedna z najbardziej dynamicznie rozwijających się firm budowlanych na Podkarpaciu. Jakość wykonywanych przez nas prac, dotrzymywanie terminów, rzetelne wykonywanie kosztorysów i profesjonalne podejście do Klienta od samego początku funkcjonowania buduje naszą renomę i zaufanie'

const Main = styled.main`
  width: 100%;
`
const InnerWrapper = styled.div`
  padding-top: 160px;

  ${({ theme }) => theme.media.lg.min} {
    padding-top: 200px;
  }

  ${({ theme }) => theme.media.xl.min} {
    display: grid;
    grid-template-columns: 1.1fr 1fr;
    column-gap: 50px;
    padding-top: 250px;
    padding-left: ${({ theme }) => theme.container.paddingMD};
    padding-right: ${({ theme }) => theme.container.paddingMD};
  }
`

const StyledContainer = styled(Container)`
  ${({ theme }) => theme.media.xl.min} {
    max-width: 800px;
    margin-left: auto;
    margin-right: unset;
    padding: 0;
  }
`

const FlatCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`

const FlatCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  ${({ theme }) => theme.media.xl.min} {
    position: relative;
    width: 100%;
    justify-content: flex-start;
  }
`

const FlatCardHeaderLink = styled.a`
  position: absolute;
  top: -40px;
  left: 0;

  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.tertiary};
  cursor: pointer;
  transition: 0.3s;

  span {
    color: ${({ theme }) => theme.colors.dark200};
  }

  :hover {
    color: ${({ theme }) => theme.colors.dark200};

    span {
      color: ${({ theme }) => theme.colors.tertiary};
    }
  }
`

const PdfLink = styled.a`
  width: 42px;
  height: 42px;
`

const PdfLinkButton = styled.button`
  width: 42px;
  height: 42px;
  margin-left: 20px;
`

const FlatInfo = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    display: none;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.primary};
  }

  ${({ theme }) => theme.media.xl.min} {
    flex-direction: row;
    justify-content: space-between;

    &:after {
      display: block;
    }
  }
`

const FlatInfoItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  ${({ theme }) => theme.media.xl.min} {
    width: auto;
    flex-direction: column;
  }
`

const FlatImgWrapper = styled.div<{ visible?: boolean }>`
  display: none;
  margin: 20px 0;

  ${({ visible }) =>
    visible &&
    css`
      display: block;
    `}

  ${({ theme }) => theme.media.xl.min} {
    margin: 60px 0 20px;
  }
`

// const PNGWrapper = styled.div<{ visible?: boolean }>`
//   display: none;

//   ${({ visible }) =>
//     visible &&
//     css`
//       display: block;
//     `}
// `

const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 50%;
  max-width: 150px;
  background-color: ${({ theme }) => theme.colors.dark200};
  position: relative;

  //Icon
  & > :last-child {
    position: absolute;
    top: 50%;
    right: 12%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ theme }) => theme.media.xl.min} {
    width: 135px;
    max-width: 200px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    width: 175px;
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 320px;

  ${({ theme }) => theme.media.xl.min} {
    align-self: flex-end;
  }
`

const ToggleViewButton = styled.button<{ active: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 160px;
  height: 42px;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.dark200 : theme.colors.primary};
  color: ${({ theme, active }) =>
    active ? theme.colors.light : theme.colors.dark200};

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: lightgray;
      cursor: not-allowed;
    `}
`

const SimilarFlats = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 40px;
  background-color: ${({ theme }) => theme.colors.white};
`

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 80px ${({ theme }) => theme.container.paddingMD} 0;
  background-image: url('${background}');

  ${({ theme }) => theme.media.lg.min} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    max-width: 1640px;
  }

  ${({ theme }) => theme.media.xl.min} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Flat: React.FC<PageProps> = ({ pageContext: { flat }, data }) => {
  const FLAT = flat as FlatCRM

  const [currentViewMode, setCurrentViewMode] = useState<'2d' | '3d'>('3d')
  const [isError, setIsError] = useState(false)

  const { xl } = useBreakpoint()

  return (
    <Main>
      <Navigation />
      <InnerWrapper>
        <StyledContainer>
          <FlatCardWrapper>
            <FlatCardHeader>
              {xl && (
                <FlatCardHeaderLink href="/">
                  Strona Główna / <span>Mieszkanie nr {FLAT.flatNumber}</span>
                </FlatCardHeaderLink>
              )}
              <Heading
                as="h2"
                size={32}
                weight={400}
                margin="0"
                themecolor="dark200"
                dangerouslySetInnerHTML={{
                  __html: `Mieszkanie nr ${FLAT.flatNumber}`,
                }}
              />
              {!xl && (
                <PdfLink href={FLAT.PDF} target="_blank">
                  <PdfLinkButton>
                    <Icon src={pdfIcon} size={42} alt="pdf" auto />
                  </PdfLinkButton>
                </PdfLink>
              )}
            </FlatCardHeader>
            <FlatInfo>
              <FlatInfoItem>
                <Text size={14} themecolor="tertiary">
                  Numer
                </Text>
                <Text
                  size={14}
                  themecolor="dark200"
                  dangerouslySetInnerHTML={{ __html: FLAT.flatNumber }}
                />
              </FlatInfoItem>
              <FlatInfoItem>
                <Text size={14} themecolor="tertiary">
                  Powierzchnia
                </Text>
                <Text
                  size={14}
                  themecolor="dark200"
                  dangerouslySetInnerHTML={{
                    __html: `${FLAT.flatSize}m<sup>2</sup>`,
                  }}
                />
              </FlatInfoItem>
              <FlatInfoItem>
                <Text size={14} themecolor="tertiary">
                  Etap
                </Text>
                <Text
                  size={14}
                  themecolor="dark200"
                  dangerouslySetInnerHTML={{ __html: FLAT.stage }}
                />
              </FlatInfoItem>
              <FlatInfoItem>
                <Text size={14} themecolor="tertiary">
                  Liczba pokoi
                </Text>
                <Text
                  size={14}
                  themecolor="dark200"
                  dangerouslySetInnerHTML={{
                    __html: FLAT.flatRooms.toString(),
                  }}
                />
              </FlatInfoItem>
              <FlatInfoItem>
                <Text size={14} themecolor="tertiary">
                  Piętro
                </Text>
                <Text
                  size={14}
                  themecolor="dark200"
                  dangerouslySetInnerHTML={{
                    __html: FLAT.flatFloor.toString(),
                  }}
                />
              </FlatInfoItem>
              <FlatInfoItem>
                <Text size={14} themecolor="tertiary">
                  Status
                </Text>
                <Status>
                  <Text
                    size={16}
                    themecolor="light"
                    dangerouslySetInnerHTML={{
                      __html: getFlatStatusLabel(FLAT.flatStatus),
                    }}
                  />
                  <Icon src={rectangleLight} size={8} alt="rectangle" />
                </Status>
              </FlatInfoItem>
            </FlatInfo>
            {typeof window !== undefined && (
              <>
                <FlatImgWrapper visible={currentViewMode === '3d'}>
                  <img
                    onError={() => {
                      setIsError(true)
                      setCurrentViewMode('2d')
                    }}
                    src={FLAT.PNG3D}
                    alt="flat-3d"
                  />
                </FlatImgWrapper>
                <FlatImgWrapper visible={currentViewMode === '2d'}>
                  <img src={FLAT.PNG2D} alt="flat-2d" />
                </FlatImgWrapper>
              </>
            )}
            <Buttons>
              <ToggleViewButton
                type="button"
                active={currentViewMode === '3d'}
                onClick={() => setCurrentViewMode('3d')}
                disabled={isError}
              >
                Widok 3D
              </ToggleViewButton>
              <ToggleViewButton
                type="button"
                active={currentViewMode === '2d'}
                onClick={() => setCurrentViewMode('2d')}
              >
                Widok 2D
              </ToggleViewButton>
            </Buttons>
          </FlatCardWrapper>
        </StyledContainer>
        <FlatForm
          title="Zapytaj o mieszkanie"
          flatNumber={FLAT.flatNumber}
          stage={FLAT.stage}
        />
      </InnerWrapper>
      <SimilarFlats title="Mieszkania podobne">
        <Heading
          as="h2"
          size={36}
          weight={400}
          margin="40px"
          themecolor="dark200"
        >
          Mieszkania podobne
        </Heading>
        <Cards>
          {similarFlatsExample.map((flatItem) => (
            <SimilarFlatCard
              key={flatItem.flatNumber}
              flatNumber={flatItem.flatNumber}
              stage={flatItem.stage}
              image={flatItem.PNG}
              rooms={flatItem.flatRooms}
              area={flatItem.flatSize}
              floor={flatItem.flatFloor}
            />
          ))}
        </Cards>
      </SimilarFlats>
      <Footer
        description={footerDescription}
        cloudImg={data.cloudImg?.childImageSharp?.gatsbyImageData}
        logo={data.companyLogo2.childImageSharp?.gatsbyImageData}
      />
    </Main>
  )
}

export default Flat

export const query = graphql`
  query FlatPage {
    cloudImg: file(name: { eq: "cloud-2" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
      }
    }
    companyLogo2: file(name: { eq: "wild-estate-logo" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
      }
    }
  }
`
