export const allExampleFlats = [
  {
    investmentID: '99',
    investment: 'W Dolinie Sanu',
    name: '1',
    price: 1482000,
    isPriceVisible: true,
    area: '26.10',
    floor: '1',
    rooms: '2',
    balcony: null,
    status: 1,
    PDF: '',
    building: '1',
    PNG: 'https://i.ibb.co/0BKvJBJ/m1.png',
    PNGFloor: '',
    PNG3D: null,
    PNG360: [],
    similarFlats: null,
    tour3D: null,
    stage: '1',
  },
  {
    investmentID: '99',
    investment: 'W Dolinie Sanu',
    name: '2',
    price: 1482000,
    isPriceVisible: true,
    area: '58.10',
    floor: '1',
    rooms: '2',
    balcony: null,
    status: 1,
    PDF: '',
    building: '1',
    PNG: 'https://i.ibb.co/RH2mMLN/m3.png',
    PNGFloor: '',
    PNG3D: null,
    PNG360: [],
    similarFlats: null,
    tour3D: null,
    stage: '1',
  },
  {
    investmentID: '99',
    investment: 'W Dolinie Sanu',
    name: '3',
    price: 1482000,
    isPriceVisible: true,
    area: '44',
    floor: '1',
    rooms: '2',
    balcony: null,
    status: 1,
    PDF: '',
    building: '1',
    PNG: 'https://i.ibb.co/7KvFcG6/m2.png',
    PNGFloor: '',
    PNG3D: null,
    PNG360: [],
    similarFlats: null,
    tour3D: null,
    stage: '1',
  },
  {
    investmentID: '99',
    investment: 'W Dolinie Sanu',
    name: '4',
    price: 1482000,
    isPriceVisible: true,
    area: '77.10',
    floor: '1',
    rooms: '2',
    balcony: null,
    status: 1,
    PDF: '',
    building: '1',
    PNG: 'https://i.ibb.co/RH2mMLN/m3.png',
    PNGFloor: '',
    PNG3D: null,
    PNG360: [],
    similarFlats: null,
    tour3D: null,
    stage: '1',
  },
  {
    investmentID: '99',
    investment: 'W Dolinie Sanu',
    name: '5',
    price: 1482000,
    isPriceVisible: true,
    area: '77.10',
    floor: '1',
    rooms: '2',
    balcony: null,
    status: 1,
    PDF: '',
    building: '1',
    PNG: 'https://i.ibb.co/7KvFcG6/m2.png',
    PNGFloor: '',
    PNG3D: null,
    PNG360: [],
    similarFlats: null,
    tour3D: null,
    stage: '1',
  },
  {
    investmentID: '99',
    investment: 'W Dolinie Sanu',
    name: '6',
    price: 1482000,
    isPriceVisible: true,
    area: '77.10',
    floor: '1',
    rooms: '2',
    balcony: null,
    status: 1,
    PDF: '',
    building: '1',
    PNG: 'https://i.ibb.co/0BKvJBJ/m1.png',
    PNGFloor: '',
    PNG3D: null,
    PNG360: [],
    similarFlats: null,
    tour3D: null,
    stage: '1',
  },
]

export const similarFlatsExample = [
  {
    flatFloor: 0,
    flatNumber: 'A.2',
    flatRooms: 2,
    flatSize: 47.37,
    stage: 1,
    PNG: 'https://serwer2283083.home.pl/CMS/flats/Etap%201/PNG2/w-dolinie-sanu-1-A.2.png',
  },
  {
    flatFloor: 0,
    flatNumber: 'A.4',
    flatRooms: 2,
    flatSize: 57.55,
    stage: 1,
    PNG: 'https://serwer2283083.home.pl/CMS/flats/Etap%201/PNG2/w-dolinie-sanu-1-A.4.png',
  },
  {
    flatFloor: 0,
    flatNumber: 'A.7',
    flatRooms: 2,
    flatSize: 45.68,
    stage: 1,
    PNG: 'https://serwer2283083.home.pl/CMS/flats/Etap%201/PNG2/w-dolinie-sanu-1-A.7.png',
  },
]
