import React, { useState } from 'react'
import styled from 'styled-components'
import Icon from 'components/shared/icon'

import { Heading } from 'components/shared/typography'

import rectangleDarkIcon from 'assets/icons/rectangle-dark.svg'
import rectangleLightIcon from 'assets/icons/rectangle-light.svg'
import roomsIcon from 'assets/icons/room.svg'
import roomsIconDark from 'browser/assets/icons/room-dark.svg'
import areaIcon from 'assets/icons/area.svg'
import areaIconDark from 'browser/assets/icons/area-dark.svg'
import floorIcon from 'assets/icons/floor.svg'
import floorIconDark from 'browser/assets/icons/floor-dark.svg'

type Props = {
  flatNumber: string
  stage: number
  image: string
  rooms: number
  area: number
  floor: number
}

const Main = styled.div`
  padding: 25px;
  background: ${({ theme }) => theme.colors.white100};
  ${({ theme }) => theme.media.xl.min} {
    padding: 30px;
  }
`

const CardContent = styled.div`
  background: ${({ theme }) => theme.colors.dark200};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 140px;
  padding: 10px 30px 30px;
  margin-top: auto;
`

const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.light};
  padding-top: 10px;

  span {
    margin-left: 5px;
    text-transform: lowercase;
  }
`

const CardButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 70%;
  height: 50px;
  max-width: 215px;
  background-color: ${({ theme }) => theme.colors.primary};

  span {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.dark200};
  }

  & > :last-child {
    position: absolute;
    top: 50%;
    right: 12%;
    transform: translateY(-50%);
  }
`

const Wrapper = styled.article`
  display: flex;
  position: relative;
  flex-direction: column;
  transition: 0.3s;
  max-width: 420px;
  justify-self: center;
  margin-bottom: 60px;

  &:hover ${Main} {
    background: ${({ theme }) => theme.colors.primary};
  }

  &:hover ${CardContent} {
    background: ${({ theme }) => theme.colors.primary};

    ${ContentItem} {
      ${Label} {
        color: ${({ theme }) => theme.colors.dark200};
      }
    }
  }

  &:hover ${CardButton} {
    background: ${({ theme }) => theme.colors.dark200};
    span {
      color: ${({ theme }) => theme.colors.light};
    }
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.dark};
  cursor: pointer;
  height: 100%;
`

const CardHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
`

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  width: 100%;
  height: 260px;
  overflow: hidden;
  img {
    height: auto;
    width: 100%;
    max-height: 290px;
  }
  & > div:first-child {
    height: 100%;
  }
  ${({ theme }) => theme.media.md.min} {
    height: 350px;
    img {
      height: auto;
      width: auto;
      max-height: 100%;
    }
  }
  ${({ theme }) => theme.media.lg.min} {
    height: 300px;
  }
  ${({ theme }) => theme.media.xxxl.min} {
    height: 350px;
  }
`

const SimilarFlatCard: React.FC<Props> = ({
  flatNumber,
  stage,
  image,
  rooms,
  area,
  floor,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Wrapper
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <a href={`/mieszkanie/${stage}/${flatNumber}`}>
        <InnerWrapper>
          <Main>
            <CardHeading>
              <Heading
                as="h6"
                size={20}
                weight={400}
                dangerouslySetInnerHTML={{
                  __html: `Mieszkanie nr ${flatNumber}`,
                }}
              />
              <Heading
                as="h6"
                size={20}
                weight={400}
                dangerouslySetInnerHTML={{
                  __html: `Etap ${stage}`,
                }}
              />
            </CardHeading>
            <ImageWrapper>
              <img src={image} alt={`Mieszkanie nr ${flatNumber} plan`} />
            </ImageWrapper>
          </Main>
          <CardContent>
            <ContentItem>
              <Icon
                src={isHovered ? roomsIconDark : roomsIcon}
                size={20}
                alt="content-item"
              />
              <Label>
                {rooms}
                <span>Pokoje</span>
              </Label>
            </ContentItem>
            <ContentItem>
              <Icon
                src={isHovered ? areaIconDark : areaIcon}
                size={20}
                alt="content-item"
              />
              <Label>
                {floor}
                <span>Piętro</span>
              </Label>
            </ContentItem>
            <ContentItem>
              <Icon
                src={isHovered ? floorIconDark : floorIcon}
                size={20}
                alt="content-item"
              />
              <Label>
                {area} m<sup>2</sup>
              </Label>
            </ContentItem>
            <CardButton>
              <span>Sprawdź</span>
              <Icon
                src={isHovered ? rectangleLightIcon : rectangleDarkIcon}
                width={10}
                alt="rectangle"
              />
            </CardButton>
          </CardContent>
        </InnerWrapper>
      </a>
    </Wrapper>
  )
}

export default SimilarFlatCard
